import { useLocation } from "react-router-dom";
import {
  SidebarContent,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
  useSidebar,
} from "../../../../../components/ui/sidebar";
import {
  LayoutDashboard,
  FileStack,
  Building,
  ClipboardCheck,
  Calendar,
  Wrench,
  Users2,
  Computer,
  Boxes,
  FileCheck2,
  ShieldCheck,
  ArrowUpToLine,
  UtilityPole,
  GaugeCircle,
  Tag,
  KanbanSquare,
  LineChart,
  Building2,
} from "lucide-react";
import { NavigationMenuItem } from "../../../../../components/navigation/NavigationMenuItem";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";
import useModuleAccess from "../../../../../hooks/useModuleAccess";
import {
  useBimEnabled,
  useCustomWorkPermitEnabled,
  useEscalationEnabled,
  usePdfReportEnabled,
  useSchedulerEnabled,
} from "../../../../../hooks/useFeatures";
import { useAppSelector } from "../../../../../redux/store";

export function CerevSidebarContent() {
  const location = useLocation();
  const activeProject = useAppSelector((state) => state.root.activeProject);
  const schedulerEnabled = useSchedulerEnabled();
  const bimEnabled = useBimEnabled();
  const isCustomPtwEnabled = useCustomWorkPermitEnabled();
  const isPdfReportEnabled = usePdfReportEnabled();
  const isEscalationEnabled = useEscalationEnabled();
  const {
    workOrderHasAccess,
    workRequestHasAccess,
    pmHasAccess,
    vendorHasAccess,
    assetHasAccess,
    workPermitHasAccess,
    legalComplianceHasAccess,
    inventoryHasAccess,
    dashboardHasAccess,
    tnbBillHasAccess,
    metersHasAccess,
    escalationHasAccess,
  } = useModuleAccess();
  const { state, isMobile } = useSidebar();
  const isCollapsed = state === "collapsed";

  const versionButton = (
    <SidebarMenuButton asChild>
      <div className="flex items-center gap-2">
        <Tag className="size-4" />
        <span className="truncate text-xs">v2.8.4</span>
      </div>
    </SidebarMenuButton>
  );

  const isActive = (path: string) => location.pathname.includes(path);

  // Command Centre specific navigation
  if (activeProject?.code === "RCICC88") {
    return (
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel>Command Centre</SidebarGroupLabel>
          <SidebarMenu>
            <NavigationMenuItem
              to="/home/cc-work-request-analytics"
              icon={<LayoutDashboard className="size-4" />}
              label="Work Request"
              isActive={isActive("/home/cc-work-request-analytics")}
            />
            <NavigationMenuItem
              to="/home/cc-work-order-analytics"
              icon={<LayoutDashboard className="size-4" />}
              label="Work Order"
              isActive={isActive("/home/cc-work-order-analytics")}
            />
            <NavigationMenuItem
              to="/home/cc-equipment-analytics"
              icon={<LayoutDashboard className="size-4" />}
              label="Equipment"
              isActive={isActive("/home/cc-equipment-analytics")}
            />
            <NavigationMenuItem
              to="/home/cc-staff-analytics"
              icon={<Users2 className="size-4" />}
              label="Staff"
              isActive={isActive("/home/cc-staff-analytics")}
            />
          </SidebarMenu>
        </SidebarGroup>

        <SidebarGroup className="mt-auto">
          <SidebarMenu>
            <SidebarMenuItem>
              {isCollapsed && !isMobile ? (
                <Tooltip>
                  <TooltipTrigger asChild>{versionButton}</TooltipTrigger>
                  <TooltipContent
                    side="right"
                    align="center"
                    hidden={!isCollapsed || isMobile}
                  >
                    v2.8.5
                  </TooltipContent>
                </Tooltip>
              ) : (
                versionButton
              )}
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
    );
  }

  // Original navigation for non-CC projects
  return (
    <SidebarContent>
      <SidebarGroup>
        <SidebarGroupLabel>Reporting</SidebarGroupLabel>
        <SidebarMenu>
          {dashboardHasAccess && (
            <NavigationMenuItem
              to="/home/dashboard"
              icon={<LayoutDashboard className="size-4" />}
              label="Overview"
              isActive={isActive("/home/dashboard")}
            />
          )}
          {isPdfReportEnabled && (
            <NavigationMenuItem
              to="/home/pdf-report"
              icon={<FileStack className="size-4" />}
              label="PDF Reports"
              isActive={isActive("/home/pdf-report")}
            />
          )}
          {bimEnabled && (
            <NavigationMenuItem
              to="/home/bim"
              icon={<Building className="size-4" />}
              label="3D Building"
              isActive={isActive("/home/bim")}
            />
          )}
        </SidebarMenu>
      </SidebarGroup>

      <SidebarGroup>
        <SidebarGroupLabel>CMMS Modules</SidebarGroupLabel>
        <SidebarMenu>
          {workRequestHasAccess && (
            <NavigationMenuItem
              to="/home/work-requests"
              icon={<KanbanSquare className="size-4" />}
              label="Work Request"
              isActive={isActive("/home/work-requests")}
            />
          )}
          {workOrderHasAccess && (
            <NavigationMenuItem
              to="/home/work-orders"
              icon={<ClipboardCheck className="size-4" />}
              label="Work Order"
              isActive={isActive("/home/work-orders")}
            />
          )}
          {workOrderHasAccess && schedulerEnabled && (
            <NavigationMenuItem
              to="/home/wo-scheduler"
              icon={<Calendar className="size-4" />}
              label="WO Scheduler"
              isActive={isActive("/home/wo-scheduler")}
            />
          )}
          {pmHasAccess && (
            <>
              <NavigationMenuItem
                to="/home/pm"
                icon={<Wrench className="size-4" />}
                label="PM"
                isActive={isActive("/home/pm")}
              />
              <NavigationMenuItem
                to="/home/template"
                icon={<Wrench className="size-4" />}
                label="PM Template"
                isActive={isActive("/home/template")}
              />
              {schedulerEnabled && (
                <NavigationMenuItem
                  to="/home/scheduler-pm"
                  icon={<Calendar className="size-4" />}
                  label="PM Scheduler"
                  isActive={isActive("/home/scheduler-pm")}
                />
              )}
            </>
          )}
          {vendorHasAccess && (
            <NavigationMenuItem
              to="/home/vendors"
              icon={<Users2 className="size-4" />}
              label="Vendors"
              isActive={isActive("/home/vendors")}
            />
          )}
          {assetHasAccess && (
            <NavigationMenuItem
              to="/home/assets"
              icon={<Computer className="size-4" />}
              label="Equipments"
              isActive={isActive("/home/assets")}
            />
          )}
          {inventoryHasAccess && (
            <NavigationMenuItem
              to="/home/inventory"
              icon={<Boxes className="size-4" />}
              label="Inventory"
              isActive={isActive("/home/inventory")}
            />
          )}
          {workPermitHasAccess && (
            <>
              {isCustomPtwEnabled ? (
                <>
                  <NavigationMenuItem
                    to="/home/ptw"
                    icon={<FileCheck2 className="size-4" />}
                    label="Work Permits"
                    isActive={isActive("/home/ptw")}
                  />
                  <NavigationMenuItem
                    to="/home/wp-template"
                    icon={<FileCheck2 className="size-4" />}
                    label="WP Templates"
                    isActive={isActive("/home/wp-template")}
                  />
                </>
              ) : (
                <NavigationMenuItem
                  to="/home/work-permit"
                  icon={<FileCheck2 className="size-4" />}
                  label="Work Permit"
                  isActive={isActive("/home/work-permit")}
                />
              )}
            </>
          )}
          {legalComplianceHasAccess && (
            <NavigationMenuItem
              to="/home/legal-compliance"
              icon={<ShieldCheck className="size-4" />}
              label="Legal Compliance"
              isActive={isActive("/home/legal-compliance")}
            />
          )}
          {escalationHasAccess && isEscalationEnabled && (
            <NavigationMenuItem
              to="/home/escalations"
              icon={<ArrowUpToLine className="size-4" />}
              label="Escalations"
              isActive={isActive("/home/escalations")}
            />
          )}
        </SidebarMenu>
      </SidebarGroup>

      <SidebarGroup>
        <SidebarGroupLabel>Energy</SidebarGroupLabel>
        <SidebarMenu>
          {tnbBillHasAccess && (
            <NavigationMenuItem
              to="/home/tnb-bills"
              icon={<UtilityPole className="size-4" />}
              label="TNB Bills"
              isActive={isActive("/home/tnb-bills")}
            />
          )}
          {metersHasAccess && (
            <NavigationMenuItem
              to="/home/meters"
              icon={<GaugeCircle className="size-4" />}
              label="Meters"
              isActive={isActive("/home/meters")}
            />
          )}
        </SidebarMenu>
      </SidebarGroup>

      <SidebarGroup className="mt-auto">
        <SidebarMenu>
          <SidebarMenuItem>
            {isCollapsed && !isMobile ? (
              <Tooltip>
                <TooltipTrigger asChild>{versionButton}</TooltipTrigger>
                <TooltipContent
                  side="right"
                  align="center"
                  hidden={!isCollapsed || isMobile}
                >
                  v2.8.5
                </TooltipContent>
              </Tooltip>
            ) : (
              versionButton
            )}
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarGroup>
    </SidebarContent>
  );
}
